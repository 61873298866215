import  RunFunctions from './modules/pure.js'
import bvi from "bvi"
import { slideDown, slideUp, fadeOut } from './modules/pure.js'
import "../sass/style.scss"
document.addEventListener("DOMContentLoaded", () => {
   new isvek.Bvi();
   const nav = document.querySelectorAll('.docs-tab__item_nav')
   const tabContent = document.querySelectorAll('.docs-tab__item_content')
	if(nav){
      nav.forEach(item => {
            item.addEventListener('click', (event) => {
            let elem = event.currentTarget
               let id = elem.dataset.id
               if(elem.classList.contains('active')){
                     elem.classList.remove('active')
                     slideUp(document.getElementById(`${id}`),400)
                  }else{
                     elem.classList.add('active')
                     slideDown(document.getElementById(`${id}`),400)
                  }
            })
         })

      }
   let searchBtn = document.querySelector('.search-box')
   let searchWrapper = document.querySelector('.search-wrapper')
   let formClose = document.querySelector('.form-close')
   searchBtn.addEventListener('click',(e) => {
      e.stopPropagation();
      searchWrapper.classList.add('active')
      })

   formClose.addEventListener('click',(e) => {
      e.stopPropagation();
      searchWrapper.classList.remove('active')
      })
   document.addEventListener('click', function(e) {
      const target = e.target;
      const its_box = target == searchWrapper || searchWrapper.contains(target);
      const its_btn = target == searchBtn;
      const is_active = searchWrapper.classList.contains('active');

      if (!its_box && !its_btn && is_active) {
         searchWrapper.classList.remove('active')
      }
   })

   let toggler = document.querySelector('.mobile-menu-box')
   let closeMobie = document.querySelector('.close-mobie')
   let menu = document.getElementById('menu_header')
       toggler.addEventListener('click',(e) => {
         menu.classList.add('active')
         setTimeout(() => {
            closeMobie.classList.add('active')
         }, 550);
       })
       closeMobie.addEventListener('click',(e) => {
         menu.classList.remove('active')
         e.currentTarget.classList.remove('active')
       })

  })

